import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Success = () => {
  const [seconds, setSeconds] = useState(5)

  useEffect(() => {
    setTimeout(() => {
      setSeconds(seconds - 1)
    }, 1000)
    if (seconds === 0 && typeof window !== `undefined`) {
      window.location.replace(`/`)
    }
  })

  const redirect = () => {
    return (
      <p style={{ color: 'white', fontSize:'17px' }}>
        Redirection à la page d'accueil dans {seconds} secondes <br />
        <Link
          style={{ color: 'white', fontSize: '18px', fontWeight: '700' }}
          to="/"
        >
          {' '}
          Retour
        </Link>
      </p>
    )
  }
  return (
    <Layout
      title="Message reçu !"
      desc="Nous reviendrons vers vous dans les plus brefs délais"
      svg="success"
      redirect={redirect}
    >
      <SEO title="Message envoyé" />
    </Layout>
  )
}
export default Success
